import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, useMediaQuery } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Person2Icon from "@mui/icons-material/Person2";
import { ChartBar as ChartBarIcon } from "../icons/chart-bar";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import CasinoIcon from "@mui/icons-material/Casino";
import TuneIcon from "@mui/icons-material/Tune";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PaidIcon from "@mui/icons-material/Paid";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import SettingsIcon from "@mui/icons-material/Settings";
import ApiIcon from "@mui/icons-material/Api";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import CachedIcon from "@mui/icons-material/Cached";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useEffect, useState } from "react";
import { Logo } from "./logo";
import { NavItem } from "./nav-item";
import { Users } from "../icons/users";
import { t } from "i18next";

export const DashboardSidebar = (props) => {
    const items = [
        {
            href: "/admin",
            icon: <ChartBarIcon fontSize="small" />,
            title: t("statistical")
        },
        {
            href: "/admin/users",
            icon: <Person2Icon fontSize="small" />,
            title: t("users")
        },
        {
            href: "/admin/allNV",
            icon: <PeopleAltIcon fontSize="small" />,
            title: t("agent")
        },
        /*{
            href: "/admin/setgreedy",
            icon: <CasinoIcon fontSize="small" />,
            title: "Greedy"
        },
        {
            href: "/admin/setcatdog",
            icon: <CasinoIcon fontSize="small" />,
            title: "Cat Dog"
        },*/
        {
            href: "/admin/setxd45s",
            icon: <CasinoIcon fontSize="small" />,
            title: "XD 45s"
        },
        {
            href: "/admin/setxd60s",
            icon: <CasinoIcon fontSize="small" />,
            title: "XD 60s"
        },
        {
            href: "/admin/settx45s",
            icon: <CasinoIcon fontSize="small" />,
            title: "TX 45s"
        },
        {
            href: "/admin/settx60s",
            icon: <CasinoIcon fontSize="small" />,
            title: "TX 60s"
        },
        {
            href: "/admin/setxs75s",
            icon: <SmartToyIcon fontSize="small" />,
            title: "XS 75s"
        },
        {
            href: "/admin/setxs120s",
            icon: <SmartToyIcon fontSize="small" />,
            title: "XS 120s"
        },
        {
            href: "/admin/request",
            icon: <CurrencyExchangeIcon fontSize="small" />,
            title: t("withdraw")
        },
        {
            href: "/admin/add",
            icon: <PaidIcon fontSize="small" />,
            title: t("recharge")
        },
        /*{
            href: "/admin/promo",
            icon: <SportsEsportsIcon fontSize="small" />,
            title: t("setting_promo")
        },*/
        {
            href: "/admin/historyapi",
            icon: <ApiIcon fontSize="small" />,
            title: t("history_api")
        },
        {
            href: "/admin/history",
            icon: <SportsEsportsIcon fontSize="small" />,
            title: t("history_play")
        },
        {
            href: "/admin/refund",
            icon: <CachedIcon fontSize="small" />,
            title: t("history_refund")
        },
        /*{
            href: "/admin/bankout",
            icon: <AccountBalanceWalletIcon fontSize="small" />,
            title: "Chuyển khoản"
        },*/
        {
            href: "/admin/trathuong",
            icon: <RocketLaunchIcon fontSize="small" />,
            title: t("lottery_reward")
        },
        {
            href: "/admin/setting",
            icon: <TuneIcon fontSize="small" />,
            title: t("setting_rate")
        },
        {
            href: "/admin/config",
            icon: <SettingsIcon fontSize="small" />,
            title: t("setting")
        },
        /*{
            href: "/admin/setting/profit",
            icon: <TuneIcon fontSize="small" />,
            title: t("setting_vip")
        },
        {
            href: "/admin/setting/money",
            icon: <TuneIcon fontSize="small" />,
            title: "Cài đặt hoa hồng",
        },
        {
            href: "/admin/employee",
            icon: <Users fontSize="small" />,
            title: "Thêm mới nhân viên",
        },*/
        {
            href: "/admin/bank",
            icon: <AccountBalanceIcon fontSize="small" />,
            title: t("setting_bank")
        },
        {
            href: "/admin/settingnotify",
            icon: <NotificationsActiveIcon fontSize="small" />,
            title: t("setting_notify")
        }
    ];
    const subItems = [
        {
            href: "/admin",
            icon: <ChartBarIcon fontSize="small" />,
            title: t('statistical')
        },
        {
            href: "/admin/users",
            icon: <Person2Icon fontSize="small" />,
            title: t('users')
        },
        {
            href: "/admin/request",
            icon: <CurrencyExchangeIcon fontSize="small" />,
            title: t('withdraw')
        },
        {
            href: "/admin/add",
            icon: <PaidIcon fontSize="small" />,
            title: t('recharge')
        },
        {
            href: "/admin/history",
            icon: <SportsEsportsIcon fontSize="small" />,
            title: t('history_play')
        },
        /*{
            href: "/admin/historyapi",
            icon: <ApiIcon fontSize="small" />,
            title: t('history_casino')
        },*/
        {
            href: "/admin/refund",
            icon: <CachedIcon fontSize="small" />,
            title: t('history_refund')
        }
    ];
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
        defaultMatches: true,
        noSsr: false
    });
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (props.open == true) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [props.open]);
    const sendData = () => {
        props.callback(false);
    };
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    useEffect(() => {
        const timer = setInterval(() => {
            const storedProfile = JSON.parse(localStorage.getItem("profile"));
            if (storedProfile && (profile === undefined || profile === null)) {
                setProfile(storedProfile);
            } else {
                clearInterval(timer);
            }
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [profile]);

    const content = (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%"
                }}
                className="admin-theme">
                <div>
                    <Box sx={{ p: 3 }}>
                        <Link
                            to="/"
                            style={{
                                textDecoration: "none",
                                alignItems: "center",
                                display: "flex"
                            }}>
                            <Logo
                                sx={{
                                    height: 42,
                                    width: 42
                                }}
                            />
                            <div
                                style={{
                                    paddingLeft: "20px",
                                    fontWeight: 700,
                                    color: "gray",
                                    fontSize: "30px"
                                }}>
                                ADMIN
                            </div>
                        </Link>
                    </Box>
                </div>
                <Divider
                    sx={{
                        borderColor: "#2D3748",
                        my: 0
                    }}
                />
                <Box sx={{ flexGrow: 1, p: 0 }}>
                    {profile?.permission?.full == 1 ? (
                        <>
                            {items.map((item, index) => (
                                <NavItem key={item.title} icon={item.icon} to={item.href} title={item.title} />
                            ))}
                        </>
                    ) : (
                        <>
                            {subItems.map((item, index) => (
                                <NavItem key={item.title} icon={item.icon} to={item.href} title={item.title} />
                            ))}
                        </>
                    )}
                </Box>
                <Divider sx={{ borderColor: "#2D3748" }} />
                <Box
                    sx={{
                        px: 2,
                        py: 3
                    }}></Box>
            </Box>
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open={open}
                PaperProps={{
                    sx: {
                        backgroundColor: "neutral.900",
                        color: "#FFFFFF",
                        width: 250
                    }
                }}
                variant="permanent">
                {content}
            </Drawer>
        );
    }
    return (
        <Drawer
            anchor="left"
            onClose={sendData}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: "neutral.900",
                    color: "#FFFFFF",
                    width: 250
                }
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary">
            {content}
        </Drawer>
    );
};

// DashboardSidebar.propTypes = {
// 	onClose: PropTypes.func,
// 	open: PropTypes.bool,
// };
