import { Box, Container, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField } from "@mui/material";
import swal from "sweetalert";
import { t } from "i18next";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function Promo() {
    const [load, setLoad] = useState(true);
    const [isShow, setShow] = useState(false);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const [promo, setPromo] = useState(null);
    const [promoList, setPromoList] = useState(null);
    useEffect(() => {
        if (load === true) {
            axios.get(`${process.env.REACT_APP_API_URL}/promo/get`, {}).then((res) => {
                setPromoList(res.data.data);
                setLoad(false);
            });
        }
    }, [load]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            code: e.target.code.value,
            num: Number(e.target.num.value),
            money: Number(e.target.money.value)
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/promo/create`, formData)
            .then((res) => {
                setLoad(true);
                swal(t('success'), "", "success").then((value) => {
                    window.location.reload();
                });
            })
            .catch((res) => setLoad(true));
    };
    const handleSubmitnotify = (e) => {
        e.preventDefault();
        const formData = {
            code: e.target.code.value,
            num: Number(e.target.num.value),
            money: Number(e.target.money.value)
        };

        axios.post(`${process.env.REACT_APP_API_URL}/promo/edit`, formData).then((res) => {
            swal(t('success'), "", "success").then(() => window.location.reload());
            setLoad(true);
            setShow(false);
        });
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {profile?.permission?.full == 1 ? (
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 8
                            }}>
                            <Container maxWidth={false}>
                                <div style={{ fontSize: "25px", fontWeight: 700 }}>{t("setting_promo")}</div>
                                <div style={{ marginTop: "20px" }}>
                                    <form onSubmit={handleSubmit} className="form-admin" style={{ maxWidth: "400px" }}>
                                        <div>
                                            <div>
                                                <label>{t("code_promo")}</label>
                                                <input required name="code" id="code" />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>{t("code_uses")}</label>
                                                <input name="num" id="num" type="number" required />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>{t("money")}</label>
                                                <input name="money" id="money" type="number" required />
                                            </div>
                                        </div>
                                        <div>
                                            <button className="btn_setting">{t("add_new")}</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="form_set">
                                    <Box>
                                        <Table sx={{ width: "100%", minWidth: "60vw" }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ padding: "10px" }}>{t("code_promo")}</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>{t("code_uses")}</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>{t("money")}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{t("more")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {promoList != null ? (
                                                    <>
                                                        {promoList.map((item, index) => (
                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px", maxWidth: "200px", wordBreak: "break-word" }}>
                                                                    {item.code}
                                                                </TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.num}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px", maxWidth: "40vw", wordBreak: "break-word" }}>
                                                                    {item.money?.toLocaleString("vi-VN")}
                                                                </TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                    <Button
                                                                        className="btn-lock"
                                                                        onClick={() => {
                                                                            axios
                                                                                .post(`${process.env.REACT_APP_API_URL}/promo/delete`, {
                                                                                    id: item._id
                                                                                })
                                                                                .then((res) => {
                                                                                    swal(t("success"), "", "success");
                                                                                    setLoad(false);
                                                                                })
                                                                                .catch((res) => {
                                                                                    swal(t("error"), "", "error");
                                                                                    setLoad(false);
                                                                                });
                                                                        }}>
                                                                        {t("delete")}
                                                                    </Button>

                                                                    <Button
                                                                        onClick={() => {
                                                                            setPromo(item);
                                                                            setShow(true);
                                                                        }}>
                                                                        {t("edit")}
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <div style={{ fontSize: "16px", textAlign: "center", padding: "10px" }}>{t("waiting")}</div>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </div>
                                {isShow === true ? (
                                    <>
                                        <div className="modal">
                                            <div className="modaloverlay">
                                                <i className="ti-close closelogin"></i>
                                            </div>
                                            <div style={{ backgroundColor: "white", width: "600px" }} className="modalbody">
                                                {promo != null ? (
                                                    <>
                                                        <form onSubmit={handleSubmitnotify}>
                                                            <div className="modalinner">
                                                                <div className="modalheader">{t("edit")}</div>
                                                                <div className="modalform">
                                                                    <div className="modalformgroup" style={{ padding: "9px" }}>
                                                                        <TextField
                                                                            name="code"
                                                                            defaultValue={promo.code}
                                                                            sx={{ width: "100%" }}
                                                                            type="text"
                                                                            required
                                                                            placeholder={t("code_promo")}
                                                                        />
                                                                    </div>

                                                                    <div className="modalformgroup" style={{ padding: "9px" }}>
                                                                        <TextField
                                                                            name="num"
                                                                            defaultValue={promo.num}
                                                                            sx={{ width: "100%" }}
                                                                            type="number"
                                                                            required
                                                                            placeholder={t("code_uses")}
                                                                        />
                                                                    </div>

                                                                    <div className="modalformgroup" style={{ padding: "9px" }}>
                                                                        <TextField
                                                                            name="money"
                                                                            defaultValue={promo.money}
                                                                            sx={{ width: "100%" }}
                                                                            type="number"
                                                                            required
                                                                            placeholder={t("money")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div style={{ padding: "9px" }} className="modalformgroup"></div>
                                                                <div className="item_btn_form">
                                                                    <div className="modalformcontrols">
                                                                        <Button type="submit">{t("submit")}</Button>
                                                                    </div>
                                                                    <div className="modalformcontrols">
                                                                        <Button onClick={() => setShow(false)}>{t("close")}</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </>
                                                ) : (
                                                    <div>{t("waiting")}</div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </Container>
                        </Box>
                    ) : (
                        <div>{t("not_permission")}</div>
                    )}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default Promo;
