import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import { Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow, Pagination, TextField } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import { t } from "i18next";
import { useEffect, useState } from "react";

function Khuyenmai() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [load, setLoad] = useState(true);
    const [note, setNote] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [searched, setSearched] = useState("");
    const [pages, setPage] = useState(1);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = data2.filter((row) => {
                return row?.user?.username.toString().includes(searchedVal.toLowerCase());
            });
            setData(filteredRows);
            setPage(1);
        } else {
            setData(JSON.parse(localStorage.getItem("data")));
        }
    };
    const handleChange = (e, page) => {
        setPage(page);
    };
    useEffect(() => {
        if (load === true) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/payment/khuyenmai`, {})
                .then((res) => {
                    setData(res.data.data);
                    setData2(res.data.data);
                    setLoad(false);
                })
                .catch((err) => setLoad(false));
        }
    }, [load]);
    if (data !== null) {
        return (
            <>
                <ThemeProvider theme={theme}>
                    <DashboardLayout>
                        {
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    py: 1
                                }}>
                                <Container maxWidth={false}>
                                    <div className="container_set">{t('promotion')}</div>
                                    <div className="form_set">
                                        <Box>
                                            <TextField value={searched} onChange={(searchVal) => requestSearch(searchVal.target.value)} placeholder={t('search')} />
                                            <Table className="table-admin">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{t('username')}</TableCell>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell>{t('money')}</TableCell>
                                                        <TableCell>{t('promotion')}</TableCell>
                                                        <TableCell>{t('status')}</TableCell>
                                                        <TableCell>{t('more')}</TableCell>
                                                        <TableCell>{t('time')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {data && (
                                                    <TableBody>
                                                        {data?.slice((pages - 1) * 15, (pages - 1) * 15 + 15).map((item) => (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.user?.username}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.user?.iduser}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.money?.toLocaleString("vi-VN")}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.km?.toLocaleString("vi-VN")}</TableCell>
                                                                    {item?.status_payment === "Pending" ? (
                                                                        <TableCell>
                                                                            <span className="badge warning">
                                                                                {item?.status_payment}
                                                                            </span>
                                                                        </TableCell>
                                                                    ) : null}
                                                                    {item?.status_payment === "Success" ? (
                                                                        <TableCell>
                                                                            <span className="badge success">
                                                                                {item?.status_payment}
                                                                            </span>
                                                                        </TableCell>
                                                                    ) : null}
                                                                    {item?.status_payment === "Deny" ? (
                                                                        <TableCell>
                                                                            <span className="badge error">
                                                                                {item?.status_payment}
                                                                            </span>
                                                                        </TableCell>
                                                                    ) : null}
                                                                    <TableCell>
                                                                        <textarea
                                                                            type="text"
                                                                            name={`note-${item?._id}`}
                                                                            value={item?.note}
                                                                            disabled={item?.status_payment !== "Pending"}
                                                                            onChange={(e) => setNote(e.target.value)}
                                                                            onKeyUp={(e) => setNote(e.target.value)}
                                                                        />
                                                                        <Button
                                                                            onClick={() => {
                                                                                const formData = {
                                                                                    id: item?._id,
                                                                                    status: "Accept",
                                                                                    note: note
                                                                                };
                                                                                axios
                                                                                    .post(`${process.env.REACT_APP_API_URL}/payment/update`, formData)
                                                                                    .then((res) => {
                                                                                        swal(t('success'),"","success").then(() => window.location.reload())
                                                                                    })
                                                                                    .catch((err) => setLoad(true));
                                                                            }}
                                                                            disabled={item?.status_payment !== "Pending"}>
                                                                            {t('submit')}
                                                                        </Button>
                                                                        <Button
                                                                            onClick={() => {
                                                                                const formData = {
                                                                                    id: item?._id,
                                                                                    status: "Deny",
                                                                                    note: note
                                                                                };
                                                                                axios
                                                                                    .post(`${process.env.REACT_APP_API_URL}/payment/update`, formData)
                                                                                    .then((res) => {
                                                                                        swal(t('success'), "", "success").then(() => window.location.reload());
                                                                                    })
                                                                                    .catch((err) => setLoad(true));
                                                                            }}
                                                                            disabled={item?.status_payment !== "Pending"}>
                                                                            {t('deny')}
                                                                        </Button>
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item?.createdAt))}</TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}
                                                    </TableBody>
                                                )}
                                            </Table>
                                        </Box>
                                    </div>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            pt: 3
                                        }}>
                                        {data != null ? <Pagination color="primary" count={Math.floor(data.length / 15) + 1} size="small" onChange={handleChange} /> : null}
                                    </Box>
                                </Container>
                            </Box>
                        }
                    </DashboardLayout>
                </ThemeProvider>
            </>
        );
    }
}
export default Khuyenmai;
