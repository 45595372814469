import { Box, Container, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField } from "@mui/material";
import swal from "sweetalert";
import { t } from "i18next";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import React, { useEffect, useState, useRef, useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image"];
function SettingNotify() {
    const [notify, setNotify] = useState();
    const [load, setLoad] = useState(true);
    const [isShow, setShow] = useState(false);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const [content, setContent] = useState("");
    const handleChangeContent = (value) => {
        setContent(value);
    };

    const [notifyItem, setNotifyItem] = useState();
    useEffect(() => {
        if (load === true) {
            axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
                setNotify(res.data.data);
                setLoad(false);
            });
        }
    }, [load]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            title: e.target.title.value,
            image: e.target.image.value,
            content: content
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/newnotify`, formData)
            .then((res) => {
                setLoad(true);
                swal(t("success", "", "success")).then((value) => {
                    window.location.reload();
                });
            })
            .catch((res) => setLoad(true));
    };
    const handleSubmitnotify = (e) => {
        e.preventDefault();
        const formData = {
            id: notifyItem._id,
            title: e.target.title.value,
            image: e.target.image.value,
            content: content
        };

        axios.post(`${process.env.REACT_APP_API_URL}/auth/editlistnotify`, formData).then((res) => {
            swal(t("success"), "", "success").then(() => window.location.reload());
            setLoad(true);
            setShow(false);
        });
    };
    const reactQuillRef = useRef(null);
    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append("image", file);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
            method: "POST",
            body: formData
        });

        const data = await response.json();
        return data.url;
    };
    const handleImageUpload = async (file) => {
        const imageUrl = await uploadImage(file);
        const quill = reactQuillRef.current.getEditor(); // Sử dụng reactQuillRef.current
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", imageUrl);
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                ["link", "image"],
                ["clean"]
            ]
            /*handlers: {
                image: () => {
                    console.log("a");
                    const input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");
                    input.click();
                    input.onchange = async () => {
                        const file = input.files[0];
                        if (file) {
                            await handleImageUpload(file); // Gọi hàm upload ảnh
                        }
                    };
                }
            }*/
        }
    }));
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {profile?.permission?.full == 1 ? (
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 8
                            }}>
                            <Container maxWidth={false}>
                                <div style={{ fontSize: "25px", fontWeight: 700 }}>{t("marquee")}</div>
                                <div>
                                    {notify != null ? (
                                        <>
                                            {notify.map((item, index) => (
                                                <>
                                                    {index == 0 && item.title === "marquee" ? (
                                                        <div style={{ display: "flex", margin: "10px 0 80px", border: "1px solid red", lineHeight: "2" }}>
                                                            <marquee className="thongbaomarquee">
                                                                <div className="contentHtml" dangerouslySetInnerHTML={{ __html: item.content }} />
                                                            </marquee>
                                                            <button
                                                                className="btn-marquee"
                                                                onClick={() => {
                                                                    axios
                                                                        .get(`${process.env.REACT_APP_API_URL}/auth/notifyall/${item._id}`, {})
                                                                        .then((res) => {
                                                                            setNotifyItem(res.data.data);
                                                                            setShow(true);
                                                                        })
                                                                        .catch((res) => {
                                                                            swal(t("error"), "", "error");
                                                                            setLoad(false);
                                                                        });
                                                                }}>
                                                                {t("edit")}
                                                            </button>
                                                        </div>
                                                    ) : null}
                                                </>
                                            ))}
                                        </>
                                    ) : null}
                                </div>
                                <div style={{ fontSize: "25px", fontWeight: 700 }}>{t("setting_notify")}</div>
                                <div style={{ marginTop: "20px" }}>
                                    <form onSubmit={handleSubmit} className="form-admin" style={{ maxWidth: "800px" }}>
                                        <div>
                                            <div>
                                                <label>{t("title")}</label>
                                                <input required name="title" id="title" />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>{t("url_image")}</label>
                                                <input name="image" id="image" />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>{t("content_notify")}</label>
                                                <ReactQuill value={content} onChange={handleChangeContent} theme="snow" modules={modules} formats={formats} ref={reactQuillRef} />
                                            </div>
                                        </div>
                                        <div>
                                            <button className="btn_setting">{t("add_new")}</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="form_set">
                                    <Box>
                                        <Table sx={{ width: "100%" }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ padding: "10px" }}>{t("title")}</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>{t("url_image")}</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>{t("content_notify")}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{t("more")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {notify != null ? (
                                                    <>
                                                        {notify.map((item, index) => (
                                                            <>
                                                                {index != 0 && item.title !== "marquee" ? (
                                                                    <TableRow>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px", maxWidth: "200px", wordBreak: "break-word" }}>
                                                                            {index === 1 ? (
                                                                                <span style={{ color: "red" }}>{t("banner_login")}</span>
                                                                            ) : item.title == "popup" ? (
                                                                                <span style={{ color: "red" }}>{t("popup")}</span>
                                                                            ) : (
                                                                                item.title
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                            <img alt="" src={item.image} style={{ width: "200px", height: "auto" }} />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px", maxWidth: "40vw", wordBreak: "break-word" }}>
                                                                            <div className="contentHtml" dangerouslySetInnerHTML={{ __html: item.content }} />
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    axios
                                                                                        .post(`${process.env.REACT_APP_API_URL}/auth/editlistnotify`, {
                                                                                            id: item._id,
                                                                                            isShow: !item.isShow
                                                                                        })
                                                                                        .then((res) => setLoad(true));
                                                                                }}>
                                                                                {item.isShow == true ? t("hide") : t("show")}
                                                                            </Button>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    axios
                                                                                        .get(`${process.env.REACT_APP_API_URL}/auth/notifyall/${item._id}`, {})
                                                                                        .then((res) => {
                                                                                            setNotifyItem(res.data.data);
                                                                                            setShow(true);
                                                                                        })
                                                                                        .catch((res) => {
                                                                                            swal(t("error"), "", "error");
                                                                                            setLoad(false);
                                                                                        });
                                                                                }}>
                                                                                {t("edit")}
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ) : null}
                                                            </>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <div style={{ fontSize: "16px", textAlign: "center", padding: "10px" }}>{t("waiting")}</div>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </div>
                                {isShow === true ? (
                                    <>
                                        <div className="modal">
                                            <div className="modaloverlay">
                                                <i className="ti-close closelogin"></i>
                                            </div>
                                            <div style={{ backgroundColor: "white", width: "95%", maxWidth: "1000px" }} className="modalbody">
                                                {notifyItem != null ? (
                                                    <>
                                                        <form onSubmit={handleSubmitnotify}>
                                                            <div className="modalinner">
                                                                <div className="modalheader">{t("edit")}</div>
                                                                <div className="modalform">
                                                                    {notifyItem.title !== "marquee" ? (
                                                                        <>
                                                                            {notifyItem.title !== notify[1].title && (
                                                                                <div className="modalformgroup" style={{ padding: "9px" }}>
                                                                                    <TextField
                                                                                        name="title"
                                                                                        defaultValue={notifyItem.title}
                                                                                        sx={{ width: "100%" }}
                                                                                        type="text"
                                                                                        required
                                                                                        placeholder={t("title")}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            <div style={{ padding: "9px" }} className="modalformgroup">
                                                                                <TextField
                                                                                    name="image"
                                                                                    defaultValue={notifyItem.image}
                                                                                    sx={{ width: "100%" }}
                                                                                    type="text"
                                                                                    required
                                                                                    placeholder={t("url_image")}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="modalformgroup" style={{ padding: "9px", display: "none" }}>
                                                                                <TextField
                                                                                    name="title"
                                                                                    defaultValue={notifyItem.title}
                                                                                    sx={{ width: "100%" }}
                                                                                    type="text"
                                                                                    required
                                                                                    placeholder={t("title")}
                                                                                />
                                                                            </div>

                                                                            <div style={{ padding: "9px", display: "none" }} className="modalformgroup">
                                                                                <TextField
                                                                                    name="image"
                                                                                    defaultValue={notifyItem.image}
                                                                                    sx={{ width: "100%" }}
                                                                                    type="text"
                                                                                    required
                                                                                    placeholder={t("url_image")}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    {notifyItem.title !== notify[1].title && (
                                                                        <div style={{ padding: "9px" }} className="modalformgroup">
                                                                            <ReactQuill
                                                                                defaultValue={notifyItem.content}
                                                                                onChange={handleChangeContent}
                                                                                theme="snow"
                                                                                modules={modules}
                                                                                formats={formats}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div style={{ padding: "9px" }} className="modalformgroup"></div>
                                                                <div className="item_btn_form">
                                                                    <div className="modalformcontrols">
                                                                        <Button type="submit">{t("submit")}</Button>
                                                                    </div>
                                                                    <div className="modalformcontrols">
                                                                        <Button onClick={() => setShow(false)}>{t("close")}</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </>
                                                ) : (
                                                    <div>{t("waiting")}</div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </Container>
                        </Box>
                    ) : (
                        <div>{t("not_permission")}</div>
                    )}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default SettingNotify;
