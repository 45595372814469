// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Đọc file ngôn ngữ từ các thư mục locales
import enTranslation from './en.json';
import viTranslation from './vi.json';
import cnTranslation from './cn.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) 
  .init({
    fallbackLng: 'vi',
    debug: true,
    interpolation: {
      escapeValue: false, 
    },
    react: {
      useSuspense: false,
    },
    resources: {
      en: {
        translation: enTranslation, 
      },
      vi: {
        translation: viTranslation, 
      },
      cn: {
        translation: cnTranslation, 
      },
    },
    lng: localStorage.getItem('language') || 'vi', 
  });

export default i18n;
