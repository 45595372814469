import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import { Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { t } from "i18next";
import { GetNameChoose } from "../../funcUtils";
import useWebSocket, { ReadyState } from "react-use-websocket";
//const ws = new WebSocket(process.env.REACT_APP_API_SOCKET);

function SetCatDog() {
    const [list30, setList30] = useState();
    const [bet, setBet] = useState(null);
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(1);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const [current, setCurrent] = useState([]);
    const date = new Date();
    const currentMinute = date.getMinutes();
    const currentSecond = date.getSeconds();
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/catdog/getadmin`).then((res) => {
            setBet(res.data.data[0]);
            setDulieunhap(new Date(res.data.data[0].createdAt));
            setStart(true);
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/catdog/list30bet`, {})
            .then((res) => {
                setList30(res.data.data);
            })
            .catch(() => setList30(null));
        axios.get(`${process.env.REACT_APP_API_URL}/catdog/getcurrent`).then((res) => {
            setCurrent(res.data.data);
        });
    }, []);
    useEffect(() => {
        const timer = setInterval(() => {
            if (Math.floor(60 - (new Date() - dulieunhap) / 1000) < 0) {
                axios.get(`${process.env.REACT_APP_API_URL}/catdog/getadmin`).then((res) => {
                    setBet(res.data.data[0]);
                    setDulieunhap(new Date(res.data.data[0].createdAt));
                });
                axios
                    .get(`${process.env.REACT_APP_API_URL}/catdog/list30bet`, {})
                    .then((res) => {
                        setList30(res.data.data);
                    })
                    .catch(() => setList30(null));

                axios.get(`${process.env.REACT_APP_API_URL}/catdog/getcurrent`).then((res) => {
                    setCurrent(res.data.data);
                });
            }
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [dulieunhap]);
    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);
        let myTimeout;

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 60 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));
            if (curTime_second > 60 || curTime_second <= 0) {
                setStart(false);
                setMinute(1);
                setSecond(0);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
                axios
                    .get(`${process.env.REACT_APP_API_URL}/catdog/getcurrent`)
                    .then((res) => {
                        setCurrent(res.data.data);
                    })
                    .catch(() => setCurrent([]));
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(process.env.REACT_APP_API_SOCKET);
    useEffect(() => {
        if (lastJsonMessage !== null) {
            const { type, data } = lastJsonMessage;
            if (type === "set_catdog_response") {
                swal(t('success'), data.message, "success");
                setBet(data.result);
            }
        }
    }, [lastJsonMessage]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            id_bet: bet._id,
            result: e.target.result.value,
            second: second
        };
        if (e.target.result.value) {
            sendJsonMessage({ type: "set_catdog", data: formData });
        }
    };
    function thongke(num) {
        if (current) {
            const countTable = {};
            for (let i = 1; i <= 3; i++) {
                countTable[i] = { count: 0, totalMoney: 0 };
            }
            current.forEach((item) => {
                const detail = item.detail;
                const states = item.state.split(" ").map(Number);
                const length = states.length;

                states.forEach((state) => {
                    if (state >= 1 && state <= 3) {
                        countTable[state].count++;
                        countTable[state].totalMoney += detail["s" + state.toString()];
                    }
                });
            });
            return Number(countTable[num].totalMoney * 1000).toLocaleString("vi-VN");
        } else {
            return 0;
        }
    }
    useEffect(() => {
        if (current) {
            if (readyState !== ReadyState.OPEN) {
                const timer = setTimeout(() => {
                    window.location.reload();
                }, 1000);

                return () => clearTimeout(timer);
            }
        }
    }, [readyState, current]);
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {profile?.permission?.set == 1 ? (
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            <Container maxWidth={false}>
                                <div className="container_set">Cat Dog</div>

                                <div className="cycle_bet">
                                    {bet ? (
                                        <span style={{ color: "black" }} className="info_bet">
                                            {t("session")} {bet?.id_bet}
                                        </span>
                                    ) : (
                                        <span>{t("waiting")}</span>
                                    )}
                                    <div style={{ color: "black" }} className="count">
                                        0{minute} : {second < 10 ? "0" : ""}
                                        {second}
                                    </div>
                                </div>
                                <div className="form_set"></div>
                                <div className="current_bet">
                                    <div className="current_bet_head">
                                        <div>{t("cat")}</div>
                                        <div>{t("draw")}</div>
                                        <div>{t("dog")}</div>
                                    </div>

                                    <div className="current_bet_body">
                                        <div>{thongke(1)}</div>
                                        <div>{thongke(2)}</div>
                                        <div>{thongke(3)}</div>
                                    </div>
                                </div>
                                <div className="form_set">
                                    <Box>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ID</TableCell>
                                                    <TableCell>{t("username")}</TableCell>
                                                    <TableCell>{t("selected")}</TableCell>
                                                    <TableCell>{t("money")}</TableCell>
                                                    <TableCell>{t("time")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {current && current.length > 0 ? (
                                                    current.map((item) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell>{item?.user?.iduser}</TableCell>
                                                                <TableCell>{item?.user?.username}</TableCell>
                                                                <TableCell>
                                                                    {Object.entries(item?.detail).map(
                                                                        ([key, value]) =>
                                                                            value > 0 && (
                                                                                <div key={key}>
                                                                                    {GetNameChoose(key.slice(-1), null, "CatDog").replace("Chọn ", "")}:{" "}
                                                                                    {(value * 1000).toLocaleString("vi-VN")} đ
                                                                                </div>
                                                                            )
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>{Number(item?.money).toLocaleString("vi-VN")}</TableCell>
                                                                <TableCell>{formatDate(new Date(item?.createdAt))}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    ))
                                                ) : (
                                                    <tr style={{ textAlign: "center", color: "#aaa" }}>
                                                        <td colSpan={5}>{t("no_player")}</td>
                                                    </tr>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div style={{ color: "black" }} className="result_admin_choose">
                                        {t("result_recent")}{" "}
                                        {bet ? (
                                            <>
                                                <div style={{ fontWeight: "600", color: "black" }} className="title_result">
                                                    <div style={{ fontWeight: "600", color: "black", clear: "both" }}>
                                                        {GetNameChoose(bet?.result, null, "CatDog").replace("Chọn ", "")}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div>{t("waiting")}</div>
                                        )}
                                    </div>
                                    <h2>{t("edit_result")}</h2>
                                    <div>
                                        <select name="result">
                                            <option value="1" selected={bet?.result.split(" ")[0] == "1"}>
                                                {t("cat")}
                                            </option>
                                            <option value="2" selected={bet?.result.split(" ")[0] == "2"}>
                                                {t("draw")}
                                            </option>
                                            <option value="3" selected={bet?.result.split(" ")[0] == "3"}>
                                                {t("dog")}
                                            </option>
                                            <option value="4" selected={bet?.result.split(" ")[0] == "4"}>
                                                {t("cat_jar")}
                                            </option>
                                            <option value="5" selected={bet?.result.split(" ")[0] == "5"}>
                                                {t("dog_jar")}
                                            </option>
                                        </select>
                                        <button type="submit" className="btn-submit btn-admin-1" style={{ display: "inline-block", margin: "0 0 0 10px" }}>
                                            {t("submit")}
                                        </button>
                                        <button
                                            style={{ display: "inline-block", margin: "0 0 0 10px" }}
                                            className="btn-submit btn-admin-2"
                                            onClick={() => {
                                                window.location.reload(true);
                                            }}>
                                            {t("refresh")}
                                        </button>
                                    </div>
                                </form>
                                <div className="form_set">
                                    <Box>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{t("session")}</TableCell>
                                                    <TableCell>{t("result")}</TableCell>
                                                    <TableCell>{t("update")}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{t("time")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {list30
                                                    ? list30.map((item) => (
                                                          <>
                                                              <TableRow>
                                                                  <TableCell>{item?.id_bet}</TableCell>
                                                                  <TableCell>{GetNameChoose(item.result, null, "CatDog").replace("Chọn ", "")}</TableCell>
                                                                  <TableCell>
                                                                      <form
                                                                          onSubmit={(e) => {
                                                                              e.preventDefault();
                                                                              const formData = {
                                                                                  id_bet: item._id,
                                                                                  result: e.target.result.value
                                                                              };
                                                                              if (e.target.result.value) {
                                                                                  axios
                                                                                      .post(`${process.env.REACT_APP_API_URL}/catdog/update`, formData)
                                                                                      .then((res) => {
                                                                                          swal(t("success"), "", "success").then(() => {
                                                                                              window.location.reload();
                                                                                          });
                                                                                          setTimeout(function () {
                                                                                              window.location.reload();
                                                                                          }, 1500);
                                                                                      })
                                                                                      .catch((res) => swal(t("error"), "", "error"));
                                                                              }
                                                                          }}>
                                                                          <select name="result">
                                                                              <option value="1" selected={item?.result == "1"}>
                                                                                  {t("cat")}
                                                                              </option>
                                                                              <option value="2" selected={item?.result == "2"}>
                                                                                  {t("draw")}
                                                                              </option>
                                                                              <option value="3" selected={item?.result == "3"}>
                                                                                  {t("dog")}
                                                                              </option>
                                                                              <option value="4" selected={item?.result == "4"}>
                                                                                  {t("cat_jar")}
                                                                              </option>
                                                                              <option value="5" selected={item?.result == "5"}>
                                                                                  {t("dog_jar")}
                                                                              </option>
                                                                          </select>
                                                                          <button className="btn-admin-3">{t("submit")}</button>
                                                                      </form>
                                                                  </TableCell>
                                                                  <TableCell style={{ textAlign: "center" }}>{formatDate(new Date(item.createdAt))}</TableCell>
                                                              </TableRow>
                                                          </>
                                                      ))
                                                    : null}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </div>
                            </Container>
                        </Box>
                    ) : (
                        <div>{t("not_permission")}</div>
                    )}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default SetCatDog;
