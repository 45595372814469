import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { t } from "i18next";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";

function Login() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/admin");
        }
    }, []);
    const onSubmit = async (data) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username: data.username.toLowerCase(),
                password: data.password
            })
            .then((res) => {
                swal({
                    title: t('success'),
                    text: t('success'),
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("data");
                    localStorage.setItem("user", res.data.data);
                    navigate("/admin/users");
                });
            })
            .catch((err) => setErr(err.response.data.message ? err.response.data.message : "Tên đăng nhập hoặc mật khẩu không chính xác!"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="login">
            <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                <div className="inputs" style={{ marginTop: "0.5rem" }}>
                    <div className="input">
                        <input type="text" className="ip-lg" {...register("username", { required: true })} placeholder={t('username')} />
                        {errors.username ? <p>{errors.username.message}</p> : null}
                    </div>
                    <div className="input">
                        <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder={t('pwd')} />
                        {showPassword ? <Visibility onClick={toggleShowPassword} /> : <VisibilityOff onClick={toggleShowPassword} />}
                        {errors.password ? <p>{errors.password.message}</p> : null}
                    </div>
                </div>
                {err ? <p>{err}</p> : null}
                <button className="btn-red-big" type="submit">
                    {t('login')}
                </button>
            </form>
        </div>
    );
}
export default Login;
