import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./users.css";
import axios from "axios";
import { Box, Button, Container, Input, Table, Pagination, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { t } from "i18next";

function Users() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [users, setUser] = useState(null);
    const [load, setLoad] = useState(false);
    const [searched, setSearched] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    //const [employee, setEmployee] = useState();
    useEffect(() => {
        if (load == false) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getall`, {})
                .then((res) => {
                    setData(res.data.data);
                    setUser(res.data.data);
                    setLoad(true);
                    setLoading(false);
                })
                .then((res) => setLoad(true));
            /*axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getEmployee`, {})
                .then((res) => {
                    setEmployee(res.data.data);
                    setLoad(true);
                })
                .then((res) => setLoad(true));*/
        }
    }, [load]);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = data.filter((row) => {
                const a =
                    row?.username?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row?.name?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const b =
                    Number(row?.iduser).toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row?.stk_bank?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const c =
                    row?.ip?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    row?.name_bank?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const d =
                    row?.sdt?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    searchedVal.toString().toLowerCase().includes(row?.sdt?.toString().toLowerCase());
                return a || b || c || d;
            });
            setUser(filteredRows);
            setPage(1);
        } else {
            setUser(data);
        }
    };
    const [pages, setPage] = useState(1);

    const handleChange = (e, page) => {
        setPage(page);
    };
    if (data) {
        return (
            <>
                <ThemeProvider theme={theme}>
                    <DashboardLayout>
                        {
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    py: 1
                                }}>
                                {loading === true ? (
                                    <div className="loading" style={{ maxWidth: "100%" }}>
                                        <div className="loader"></div>
                                    </div>
                                ) : null}
                                <Container maxWidth={false}>
                                    <div className="container_set">{t("users")}</div>
                                    <div className="form_set">
                                        <Box>
                                            <TextField value={searched} onChange={(searchVal) => requestSearch(searchVal.target.value)} placeholder={t("search")} />
                                            <Table className="table-admin">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ padding: "10px" }}>ID</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>{t("username")}</TableCell>
                                                        {/*<TableCell sx={{ padding: "10px" }}>Ng Giới Thiệu</TableCell>*/}
                                                        <TableCell sx={{ padding: "10px" }}>{t("balance")}</TableCell>
                                                        <TableCell sx={{ padding: "10px", minWidth: "200px" }}>{t("with_reg")}</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>{t("reward")}</TableCell>
                                                        {/*
														<TableCell sx={{padding:"10px"}}>Admin Cộng</TableCell>
														<TableCell sx={{padding:"10px"}}>Admin Trừ</TableCell>
														<TableCell sx={{padding:"10px"}}>Admin Thưởng</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Tổng Cược</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Tổng Thắng</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Họ tên</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>STK</TableCell>*/}
                                                        <TableCell sx={{ padding: "10px" }}>{t("bank")}</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>{t("phone")}</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>{t("totalbet")}</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>{t("ip")}</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>{t("created")}</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>{t("more")}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {users != null ? (
                                                    <TableBody>
                                                        {users?.slice((pages - 1) * 12, (pages - 1) * 12 + 12).map((item) => (
                                                            <>
                                                                <TableRow sx={{ verticalAlign: "top" }}>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.iduser}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.username}</TableCell>
                                                                    {/*<TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                        {employee && employee.find((x) => x.code === item?.aff)?.username}
                                                                    </TableCell>*/}
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.money?.toLocaleString("vi-VN")}</TableCell>
                                                                    {/*
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                            {item?.adminadd.toLocaleString('vi-VN')}
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                            {item?.admintru.toLocaleString('vi-VN')}
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                            {item?.adminthuong.toLocaleString('vi-VN')}
                                                                        </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.totalbet.toLocaleString('vi-VN')}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.totalwin.toLocaleString('vi-VN')}</TableCell>*/}
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px", fontSize: "12px" }}>
                                                                        <form
                                                                            onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                setLoading(true);
                                                                                const dataForm = {
                                                                                    id: item?._id,
                                                                                    money: e.target.money.value,
                                                                                    type: e.target.type.value
                                                                                };
                                                                                axios.post(`${process.env.REACT_APP_API_URL}/auth/update`, dataForm).then((res) => {
                                                                                    setData(res.data.data);
                                                                                    setLoad(false);
                                                                                    setLoading(false);
                                                                                    swal(t("success"), "", "success");
                                                                                });
                                                                            }}>
                                                                            <input style={{ width: "100px" }} id={item?._id} name="money" type="number" placeholder={t("money")} />
                                                                            <Button type="submit" className="btn-primary" style={{ marginLeft: "3px" }}>
                                                                                {t("submit")}
                                                                            </Button>
                                                                            <br />
                                                                            <input name="type" type="radio" checked value="0" />
                                                                            {t("money")}
                                                                            <input name="type" type="radio" value="1" />
                                                                            {t("circle_bet")}
                                                                        </form>
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                        <form
                                                                            style={{ display: "flex", gap: "5px" }}
                                                                            onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                setLoading(true);
                                                                                const dataForm = {
                                                                                    id: item?._id,
                                                                                    money: Number(e.target.money.value),
                                                                                    detail: e.target.detail.value
                                                                                };
                                                                                axios.post(`${process.env.REACT_APP_API_URL}/auth/adminthuong`, dataForm).then((res) => {
                                                                                    setLoad(false);
                                                                                    swal(t("success"), "", "success");
                                                                                    e.target.reset();
                                                                                    setLoading(false);
                                                                                });
                                                                            }}>
                                                                            <div style={{ width: "100px" }}>
                                                                                <input
                                                                                    name="detail"
                                                                                    type="text"
                                                                                    placeholder={t("content")}
                                                                                    style={{ display: "block", marginBottom: "5px" }}
                                                                                />
                                                                                <input
                                                                                    id={item?._id}
                                                                                    name="money"
                                                                                    type="number"
                                                                                    placeholder={t("money")}
                                                                                    style={{ display: "block" }}
                                                                                />
                                                                            </div>
                                                                            <Button className="btn-primary" type="submit">
                                                                                {t("submit")}
                                                                            </Button>
                                                                        </form>
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                        <span style={{ color: "#eb2222" }}>{item?.name_bank}</span>
                                                                        <br />
                                                                        {item?.bank}
                                                                        <br />
                                                                        {item?.stk_bank}
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.sdt ? "0" + item?.sdt : ""}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.totalbet?.toLocaleString("vi-VN")}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px", maxWidth: "100px", wordBreak: "break-word" }}>
                                                                        {item?.ip}
                                                                    </TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item?.createdAt))}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", display: "flex" }}>
                                                                        {item?.isLock == false ? (
                                                                            <Button
                                                                                className="btn-lock"
                                                                                onClick={() => {
                                                                                    axios
                                                                                        .post(`${process.env.REACT_APP_API_URL}/auth/lockkey`, {
                                                                                            id: item?._id,
                                                                                            isLock: true
                                                                                        })
                                                                                        .then((res) => {
                                                                                            setLoad(false);
                                                                                            swal(t("lock") + " " + t("success"), "", "success");
                                                                                        });
                                                                                }}>
                                                                                {t("lock")}
                                                                            </Button>
                                                                        ) : (
                                                                            <Button
                                                                                className="btn-unlock"
                                                                                onClick={() => {
                                                                                    axios
                                                                                        .post(`${process.env.REACT_APP_API_URL}/auth/lockkey`, {
                                                                                            id: item?._id,

                                                                                            isLock: false
                                                                                        })
                                                                                        .then((res) => {
                                                                                            setLoad(false);
                                                                                            swal(t("unlock") + " " + t("success"), "", "success");
                                                                                        });
                                                                                }}>
                                                                                {t("unlock")}
                                                                            </Button>
                                                                        )}
                                                                        <Button onClick={() => navigate(`/admin/user/${item?._id}`)}>{t('view')}</Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}
                                                    </TableBody>
                                                ) : (
                                                    <div>{t('empty')}</div>
                                                )}
                                            </Table>
                                        </Box>
                                    </div>
                                    {searched.length < 1 && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                pt: 3
                                            }}>
                                            {data != null ? <Pagination color="primary" count={Math.floor(data.length / 12) + 1} size="small" onChange={handleChange} /> : null}
                                        </Box>
                                    )}
                                </Container>
                            </Box>
                        }
                    </DashboardLayout>
                </ThemeProvider>
            </>
        );
    } else {
        return <></>;
    }
}
export default Users;
