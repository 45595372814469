import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { Link, useNavigate } from "react-router-dom";
import Settings from "@mui/icons-material/Settings";
import TranslateIcon from '@mui/icons-material/Translate';
import Logout from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import { logout } from "../components/features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

export const MenuList = (props) => {
    const { i18n } = useTranslation(); // Hàm đổi ngôn ngữ
    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang); // Thay đổi ngôn ngữ
        localStorage.setItem("language", lang); // {t('save')} ngôn ngữ vào localStorage
        window.location.reload()
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logout());
    };
    const { t } = useTranslation();

    const { anchorEl, open, ...other } = props;
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1
                    },
                    "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0
                    }
                }
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            {...other}>
            <div style={{ textDecoration: "none", color: "#333" }} to="/account">
                <MenuItem
                    sx={{
                        pl: "30px",
                        pr: "150px",
                        pb: "10px",
                        pt: "10px"
                    }}>
                    <TranslateIcon fontSize="large" sx={{ pr: "10px" }} /> {t('language')}
                </MenuItem>
            </div>
            <div style={{ textDecoration: "none", color: "#555" }} onClick={() => handleLanguageChange('vi')}>
                <MenuItem
                    sx={{
                        pl: "30px",
                        pr: "150px",
                        pb: "10px",
                        pt: "10px"
                    }}>
                    Vietnam
                </MenuItem>
            </div>
            <div style={{ textDecoration: "none", color: "#555" }} onClick={() => handleLanguageChange('cn')}>
                <MenuItem
                    sx={{
                        pl: "30px",
                        pr: "150px",
                        pb: "10px",
                        pt: "10px"
                    }}>
                    Chinese
                </MenuItem>
            </div>
            <div style={{ textDecoration: "none", color: "#555" }} onClick={() => handleLanguageChange('en')}>
                <MenuItem
                    sx={{
                        pl: "30px",
                        pr: "150px",
                        pb: "10px",
                        pt: "10px"
                    }}>
                    English
                </MenuItem>
            </div>
            <Divider />
            <div
                style={{ textDecoration: "none", color: "#333" }}
                onClick={() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("data");
                    navigate("/");
                }}>
                <MenuItem
                    sx={{
                        pl: "30px",
                        pr: "150px",
                        pb: "10px",
                        pt: "10px"
                    }}>
                    <Logout fontSize="large" sx={{ pr: "10px" }} /> {t('logout')}
                </MenuItem>
            </div>
        </Menu>
    );
};
