import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./users.css";
import axios from "axios";
import swal from "sweetalert";
import { t } from "i18next";
import { Box, Button, Container, Input, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function AllEmployee() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [users, setUser] = useState(null);
    const [nhanvien, setNhanvien] = useState(null);
    const [load, setLoad] = useState(false);
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    const [searched, setSearched] = useState("");
    const [type, setType] = useState("customer");
    const [data2, setData2] = useState(null);
    const navigate = useNavigate();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        if (load == false) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getEmployee`, {})
                .then((res) => {
                    if (res.data.data) setData2(res.data.data);
                    setUser(res.data.data);
                    setLoad(true);
                })
                .then((res) => setLoad(true));
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getEmployee`, {params: {keyword:"nhanvien"}})
                .then((res) => {
                    setNhanvien(res.data.data);
                    setLoad(true);
                })
                .then((res) => setLoad(true));
        }
    }, [load]);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = data2.filter((row) => {
                const a = row?.username.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const b = Number(row.iduser).toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                return a || b;
            });
            setUser(filteredRows);
        } else {
            setUser(data2);
        }
    };
    const handleChange = (e) => {
        setType(e.target.value);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            username: e.target.username.value,
            password: e.target.password.value,
            code: e.target.code.value,
            type: type
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/createEmployee`, formData)
            .then((res) => {
                setLoad(false);
                swal(t('success'), "", "success");
            })
            .catch((res) => setLoad(false));
    };
    const handleSubmitLs = (e) => {
        e.preventDefault();
        const formData = {
            id: ls._id,
            permission: {
                full: Number(e.target.full.value),
                set: Number(e.target.full.value) == 1 ? 1 : Number(e.target.set.value),
                money: Number(e.target.full.value) == 1 ? 1 : Number(e.target.money.value),
                edit: Number(e.target.full.value) == 1 ? 1 : Number(e.target.edit.value),
            }
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/permission`, formData)
            .then((res) => {
                setShow(false);
                swal(t('success'), "", "success").then((value) => {
                    window.location.reload();
                });
            })
            .catch((err) => {
                setShow(false);
                swal(t('error'), "", "error");
            });
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            py: 1
                        }}>
                        <Container maxWidth={false}>
                            <div style={{ fontSize: "25px", fontWeight: 700 }}>{t("create_account")}</div>
                            <div style={{ display: "flex", justifyContent: "center", margin: "20px 0 50px" }}>
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <div style={{ width: "400px", marginBottom: "20px" }}>
                                            <input type="username" name="username" id="username" className="input_setting" placeholder={t("username")} />
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ width: "400px", marginBottom: "20px" }}>
                                            <input type="password" name="password" id="password" className="input_setting" placeholder={t("pwd")} />
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ width: "400px", marginBottom: "20px" }}>
                                            <input type="text" name="code" id="code" className="input_setting" placeholder={t("referrer_code")} />
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ width: "400px", marginBottom: "20px" }}>
                                            <label>{t("type_account")}</label> &nbsp;
                                            <select onChange={handleChange}>
                                                <option value="customer">{t("agent")}</option>
                                                <option value="admin">{t("admin")}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <button className="btn_setting">{t("submit")}</button>
                                </form>
                            </div>
                            <div className="container_set">{t("agents")}</div>
                            <div className="form_set">
                                <Box>
                                    {/*<TextField
                                        value={searched}
                                        onChange={(searchVal) => requestSearch(searchVal.target.value)}
                                        placeholder={t('search')}
                                        
                                    />*/}
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ padding: "10px" }}>ID</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>{t("username")}</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>{t("referrer_code")}</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>{t("created")}</TableCell>
                                                {/* <TableCell sx={{padding:"10px"}}>Admin Cộng</TableCell>

														<TableCell sx={{padding:"10px"}}>Admin Trừ</TableCell>
														<TableCell sx={{padding:"10px"}}>Admin Thưởng</TableCell>
														<TableCell sx={{padding:"10px"}}>Tổng Cược</TableCell>
														<TableCell sx={{padding:"10px"}}>Rút/ Nạp Tiền</TableCell>
														<TableCell sx={{padding:"10px"}}>Thưởng</TableCell> */}
                                                <TableCell sx={{ padding: "10px" }}>{t("more")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users &&
                                                users.map((item) => (
                                                    <>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.iduser}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.username}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.code}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item.createdAt))}</TableCell>
                                                            {/* <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
																	{item.adminadd.toLocaleString('vi-VN')}
																</TableCell> */}
                                                            {/* <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
																	{item.admintru.toLocaleString('vi-VN')}
																</TableCell>
																<TableCell sx={{ fontWeight: "600", padding: "10px" }}>
																	{item.adminthuong.toLocaleString('vi-VN')}
																</TableCell>
																<TableCell sx={{ fontWeight: "600", padding: "10px" }}>
																	{item.totalbet.toLocaleString('vi-VN')}
																</TableCell> */}
                                                            {/* <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			const dataForm = {
																				id: item._id,
																				money: e.target.money.value,
																			};
																			axios
																				.post(
																					`${process.env.REACT_APP_API_URL}/auth/update`,
																					dataForm
																				)
																				.then((res) => {
																					setUser(res.data.data);
																					setLoad(false);
																					swal(t('success'), "", "success")
																				});
																		}}
																	>
																		<input
																			style={{ width: "100px" }}
																			id={item._id}
																			name="money"
																			type="number"
																		/>
																		<Button type="submit">{t('submit')}</Button>
																	</form>
																</TableCell> */}
                                                            {/* <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			const dataForm = {
																				id: item._id,
																				money: e.target.money.value,
																			};
																			axios
																				.post(
																					`${process.env.REACT_APP_API_URL}/auth/adminthuong`,
																					dataForm
																				)
																				.then((res) => {
																					
																					setLoad(false);
																					swal(t('success'), "", "success")
																				});
																		}}
																	>
																		<input
																			style={{ width: "100px" }}
																			id={item._id}
																			name="money"
																			type="number"
																		/>
																		<Button type="submit">{t('submit')}</Button>
																	</form>
																</TableCell> */}
                                                            <TableCell sx={{ fontWeight: "600", display: "flex" }}>
                                                                {item.isLock == false ? (
                                                                    <Button
                                                                        className="btn-lock"
                                                                        onClick={() => {
                                                                            axios
                                                                                .post(`${process.env.REACT_APP_API_URL}/auth/lockkey`, {
                                                                                    id: item._id,

                                                                                    isLock: true
                                                                                })
                                                                                .then((res) => {
                                                                                    setLoad(false);
                                                                                    swal(t("lock") + " " + t("success"), "", "success");
                                                                                });
                                                                        }}>
                                                                        {t("lock")}
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        className="btn-unlock"
                                                                        onClick={() => {
                                                                            axios
                                                                                .post(`${process.env.REACT_APP_API_URL}/auth/lockkey`, {
                                                                                    id: item._id,

                                                                                    isLock: false
                                                                                })
                                                                                .then((res) => {
                                                                                    setLoad(false);
                                                                                    swal(t("unlock") + " " + t("success"), "", "success");
                                                                                });
                                                                        }}>
                                                                        {t("unlock")}
                                                                    </Button>
                                                                )}
                                                                <Button className="btn-primary" onClick={() => navigate(`/admin/employee/${item.code}`)}>
                                                                    {t("view_users")}
                                                                </Button>
                                                                <Button onClick={() => navigate(`/admin/user/${item?._id}`)}>{t("view")}</Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="container_set">{t("staffs")}</div>
                            <div className="form_set">
                                <Box>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ padding: "10px" }}>ID</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>{t("username")}</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>{t("referrer_code")}</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>{t("created")}</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>{t("more")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {nhanvien &&
                                                nhanvien.map((item) => (
                                                    <>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.iduser}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.username}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.code}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item.createdAt))}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", display: "flex" }}>
                                                                {item.isLock == false ? (
                                                                    <Button
                                                                        className="btn-lock"
                                                                        onClick={() => {
                                                                            axios
                                                                                .post(`${process.env.REACT_APP_API_URL}/auth/lockkey`, {
                                                                                    id: item._id,

                                                                                    isLock: true
                                                                                })
                                                                                .then((res) => {
                                                                                    setLoad(false);
                                                                                    swal(t("lock") + " " + t("success"), "", "success");
                                                                                });
                                                                        }}>
                                                                        {t("lock")}
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        className="btn-unlock"
                                                                        onClick={() => {
                                                                            axios
                                                                                .post(`${process.env.REACT_APP_API_URL}/auth/lockkey`, {
                                                                                    id: item._id,

                                                                                    isLock: false
                                                                                })
                                                                                .then((res) => {
                                                                                    setLoad(false);
                                                                                    swal(t("unlock") + " " + t("success"), "", "success");
                                                                                });
                                                                        }}>
                                                                        {t("unlock")}
                                                                    </Button>
                                                                )}
                                                                <Button className="btn-primary" onClick={() => navigate(`/admin/employee/${item.code}`)}>
                                                                    {t("view_users")}
                                                                </Button>
                                                                <Button onClick={() => navigate(`/admin/user/${item?._id}`)}>{t("view")}</Button>
                                                                <Button
                                                                    className="btn-secondary"
                                                                    onClick={() => {
                                                                        setShow(true);
                                                                        setLs(item);
                                                                    }}>
                                                                    {t("permission")}
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </div>
                        </Container>
                    </Box>
                    {isShow === true ? (
                        <>
                            <div className="modal">
                                <div className="modaloverlay">
                                    <i className="ti-close closelogin"></i>
                                </div>
                                <div className="modalbody">
                                    <form onSubmit={handleSubmitLs}>
                                        <div className="modalinner">
                                            <div className="modalheader"> {t("permission")} </div>

                                            <div className="modalform">
                                                <div className="modalformgroup d-flex" style={{ padding: "9px" }}>
                                                    <div>{t("username")}: </div>
                                                    <div>
                                                        <b>{ls?.username}</b>
                                                    </div>
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div style={{ color: "red" }}>{t("full_permission")}</div>
                                                    <TextField name="full" defaultValue={ls?.permission?.full} sx={{ width: "100%" }} type="number" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>{t("permission_1")}</div>
                                                    <TextField name="money" defaultValue={ls?.permission?.money} sx={{ width: "100%" }} type="number" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>{t("permission_2")}</div>
                                                    <TextField name="set" defaultValue={ls?.permission?.set} sx={{ width: "100%" }} type="number" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>{t("permission_3")}</div>
                                                    <TextField name="edit" defaultValue={ls?.permission?.edit} sx={{ width: "100%" }} type="number" />
                                                </div>
                                            </div>

                                            <div className="item_btn_form">
                                                <div className="modalformcontrols">
                                                    <Button type="submit">{t("submit")}</Button>
                                                </div>
                                                <div className="modalformcontrols">
                                                    <Button onClick={() => setShow(false)}>{t("close")}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    ) : null}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default AllEmployee;
